import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { DateAdapter, MatDateFormats, MatOption, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core'
import { AdminService } from '../../services/admin.service';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/api';

import { DeviceDetectorService } from 'ngx-device-detector';
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'app-monitoring-data',
  templateUrl: './monitoring-data.component.html',
  styleUrls: ['./monitoring-data.component.scss'],
  providers: [ MessageService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})
export class MonitoringDataComponent implements OnInit {
  showLoading:boolean=false
  minFromDate= new Date();
  maxToDate = new Date().setDate(2);
  arogyamitraId:any=[]
  totalPrescriptionObj:any={}
  disableSearchbutton:boolean=true
  @ViewChild('am_dropdown', { static: false }) am_dropdown!: MatSelect;

  @ViewChild('asha_dropdown') asha_dropdown!: MatSelect;
  @ViewChild('microcluster_dropdown') microcluster_dropdown!: MatSelect;
  @ViewChild('sm_dropdown') sm_dropdown!: MatSelect;

  @ViewChild("medicalConditionsView") medicalConditionsView!: ElementRef;
  @ViewChild("totalPatientsView") totalPatientsView!: ElementRef;
  @ViewChild("healthInstalledView") healthInstalledView!: ElementRef;
  @ViewChild("healthNotInstalledView") healthNotInstalledView!: ElementRef;
  @ViewChild("totalTakenVaccineView") totalTakenVaccineView!: ElementRef;
  @ViewChild("totalPendingVaccineView") totalPendingVaccineView!: ElementRef;
  @ViewChild("totalLabRequisitionsView") totalLabRequisitionsView!: ElementRef;
  @ViewChild("totalAbhaCreatedView") totalAbhaCreatedView!: ElementRef;
  @ViewChild("totalPendingVaccineForSevenDaysView") totalPendingVaccineForSevenDaysView!: ElementRef;
  @ViewChild("totalForthcomingVaccinesView") totalForthcomingVaccinesView!: ElementRef;
  @ViewChild("totalPendingVaccinesView") totalPendingVaccinesView!: ElementRef;
  @ViewChild("totalOverDueVaccinesView") totalOverDueVaccinesView!: ElementRef;
  @ViewChild("logoutPage") logoutPage!: ElementRef;
  @ViewChild("totalLabReportsView")totalLabReportsView!: ElementRef;
  @ViewChild("totalCustomersView") totalCustomersView!: ElementRef;
  @ViewChild("totalPrescriptionView") totalPrescriptionView!: ElementRef;
  
  @ViewChild('allSelected') private allSelected!: MatOption;
  
  stores = [
    { id: 2, name: "store - 1" },
    { id: 3, name: "store - 2" },
    { id: 4, name: "store - 3" },
    { id: 5, name: "Store - 4" }
  ];
  value: Array<number> = [];
  ashaArray:Array<number> = [];
  supervisorArray:Array<number> = [];

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  selectAll = false;
  selectAllAsha=false;
  allMonitoringCount: any[] = []
  allVaccinesCount:any=[]
  allReportandRequsitionCount:any=[]
  microclusterArray:any =[]
  selectedmicrocluster:any[]=[]
  cardHide:boolean=false
  startDate: any=""
  endDate: any=""
  totalCustomers:any=[]
  totalOverDueVaccines:any=[]
  totalPendingVaccines:any=[]
  totalPendingVaccineForSevenDays:any=[]
  totalForthcomingVaccines:any=[]
  totalAbhaCreated:any=[]
  healthInstalled: any = []
  healthNotInstalled: any = []
  totalLabRequisitions:any=[]
  totalPendingVaccine:any=[]
  totalPatientCount = 0
  totalPatients: any = []
  totalTakenVaccine:any=[]
  totalPrescription:any=[]
  medicalConditions: any = []
  allDocIDs: any = []
  selectedArogyamitraArray: any = []
  supervisorList: any
  arogyamitraList: any
  ashaList: any
  supervisorSelectData: any = []
  arogyamitraListSelectData: any = []
  supervisorSelect = "Select Supervisor"
  arogyamitraSelect = "Select Arogyamitra"

  searchOverDueVaccines=""
  searchMedialConditions = '';
  searchTotalPatients = '';
  searchHealthInstalled = '';
  searchHealthNotInstalled = '';
  searchTakenVaccine=""
  searchPendingVaccine=""
  searchLabRequisitions=""
  searchAbhaCreated=""
  searchPendingVaccineForSevenDays=""
  searchForthcomingVaccines=""
  searchPendingVaccines=""
  searchCustomers=""
  searchPrescription=""
  searchLabReports:any;
  supervisorName=""
  hideTextBox:boolean=false
  totalLabReports:any=[]
  dataSource: any = [];
  ashaListOrg:any=[]
  displayedColumnsTc:string[] = ['phoneNumber','docName'];
  displayedColumnsMc: string[] = ['fullName','mobile', 'medicalConditions','docName'];
  displayedColumnsTp: string[] = ['fullName', 'mobile','docName'];
  displayedColumnsHi: string[] = ['fullName','mobile' ,'createdAt','deviceInfo','appversion','dateupdated','docName'];
  displayedColumnsHni: string[] = ['fullName', 'mobile','docName'];
  displayedColumnsTv:string[]=['fullName', 'mobile','takenDate','vaccineName','dueDate','childAge','docName'];
  displayedColumnsPv:string[]=['fullName', 'mobile','takenDate','vaccineName','dueDate','childAge'];
  displayedColumnsLr:string[]=['patientName','mobile','requisitionCreated','tetsName','sharedLabName','docName']
  displayedColumnsAc:string[]=['patientName','mobile','healthIdNumber','docName']
  displayedColumnsPvsd:string[]=['fullName','childAge', 'mobile','vaccineName','dueDate','docName'];
  displayedColumnsFv:string[]=['fullName','childAge', 'mobile','vaccineName','dueDate','docName'];
  displayedColumnsPvs:string[]=['fullName','childAge', 'mobile','vaccineName','dueDate','docName'];
  displayedColumnsOv:string[]=['fullName','childAge', 'mobile','vaccineName','dueDate','docName'];
  displayedColumnsLR:string[]=['fullName','phoneNumber','uploadedBy','documentDate','createdAt','preview','docName']
  displayedColumnsRx:string[]=['fullName','phoneNumber','uploadedBy','documentDate','createdAt','preview','docName']
  fileName: any; 
  linkDocId:any=[]


  ashaRecordArray: any = [{ name: "ABHA Created", value: 50 }, {
    name: "Total Patient", value: 250
  }, { name: "Total Apps", value: 90 }, { name: "Apps NOT Installed", value: 50 }, {
    name: "ABHA Created", value: 50
  }, { name: "ABHA Created", value: 50 }, { name: "ABHA Created", value: 50 }, {
    name: "ABHA Created", value: 50

  }]
  roleOfDoctor:any=""
  linkedDocName:any=""
  linkTypeDoc:any=""
  doctorId:any=null
  mobileDevice:boolean=false

  constructor(private messageService: MessageService,private deviceService:DeviceDetectorService,private authService: AuthService,private adminService: AdminService, public dialog: MatDialog) { }

  ngOnInit(): void {
    // this.getAllMonitoringRecordsCount();
    if (this.deviceService.isMobile()) { // 768px portrait
      this.mobileDevice = true;
      console.log(">>>>>>>",this.mobileDevice)
    }
    const linkedDocString = localStorage.getItem("LinkedDoctor_DETAILS");
    const linkedDoc = linkedDocString ? JSON.parse(linkedDocString) : null;
    
    this.roleOfDoctor=linkedDoc.doctor.role
    this.linkedDocName=linkedDoc.doctor.first_name+" "+linkedDoc.doctor.last_name;
    this.doctorId=linkedDoc.doctor.doctorid
    if(this.roleOfDoctor=='s'){
      this.linkTypeDoc="S-AM"
    }
    else if(this.roleOfDoctor=='m'){
      this.linkTypeDoc="AM-AS"
      this.getSelectedArogyamitraSupervisor();
    }
   
   
    if(this.roleOfDoctor=='s'||this.roleOfDoctor=='m'){
      this.getArogyamitraArray();
    }

    if(this.roleOfDoctor=="z"){
      this.getAllLinkedRecords();

    }


   

    console.log("LinkedDoc",linkedDoc)
  
   
  }
  toggleAllSupervisor(){
   // this.linkDocId=[]
    var arr=[0];
    this.supervisorList.map((ins:any)=>{
      if(ins.doctor_id){
        arr.push(ins.doctor_id)
        this.arogyamitraId.push(ins.doctor_id)
        this.allDocIDs.push(ins.doctor_id)
      }
    })
    this.selectAll = !this.selectAll;
    if (this.selectAll == true) {
      this.supervisorArray =arr ;
    } else {
      this.supervisorArray  = [];
      this.arogyamitraId=[]
     
    }
  
   console.log(this.arogyamitraId)
  }

  toggleAllSelection() {
    this.linkDocId=[]
    var arr=[0];
    this.arogyamitraList.map((ins:any)=>{
      if(ins.linked_doc_id){
        arr.push(ins.linked_doc_id)
        this.linkDocId.push(ins.linked_doc_id)
        this.allDocIDs.push(ins.linked_doc_id)
      }
    })
    this.selectAll = !this.selectAll;
    if (this.selectAll == true) {
      this.value =arr ;
    } else {
      this.value  = [];
    }
  
   console.log(this.allDocIDs)
  }

  selectSupervisorList(id:number){
    this.linkDocId=[]
    console.log(this.supervisorArray.indexOf(id));
    if (this.supervisorArray.indexOf(id) > -1) {
      const pos = this.supervisorArray.indexOf(id);
      const pos1=this.allDocIDs.indexOf(id)
      const pos2=this.arogyamitraId.indexOf(id)
      this.supervisorArray.splice(pos, 1);
      this.allDocIDs.splice(pos1, 1);
      this.arogyamitraId.splice(pos2,1)
    } else {
      this.supervisorArray.push(id);
      this.arogyamitraId.push(id)
      this.allDocIDs.push(id)
    }
    console.log(this.supervisorArray);

    console.log(this.allDocIDs)

  }

  ashaValueChange(id:number){
    console.log(this.ashaArray.indexOf(id));
    if (this.ashaArray.indexOf(id) > -1) {
      const pos = this.ashaArray.indexOf(id);
      const pos1=this.allDocIDs.indexOf(id)
      this.ashaArray.splice(pos, 1);
      this.allDocIDs.splice(pos1, 1);
    } else {
      this.ashaArray.push(id);
     // this.linkDocId.push(id)
      this.allDocIDs.push(id)
    }
    console.log(this.ashaArray);

    console.log(this.allDocIDs)

  }
  allAshaSelection(){
    var arr=[0];
    this.ashaList.map((ins:any)=>{
      if(ins.linked_doc_id){
        arr.push(ins.linked_doc_id)
       
        this.allDocIDs.push(ins.linked_doc_id)
      }
    })
    this.selectAll = !this.selectAll;
    if (this.selectAll == true) {
      this.ashaArray =arr ;
    } else {
      this.ashaArray  = [];
    }
  
   console.log(this.allDocIDs)
  
  }




  valueChange(id: number) {
    // this.linkDocId=[]
    console.log(this.value.indexOf(id));
    if (this.value.indexOf(id) > -1) {
      const pos = this.value.indexOf(id);
      const pos1=this.allDocIDs.indexOf(id)
      const pos2=this.linkDocId.indexOf(id)
      this.value.splice(pos, 1);
      this.allDocIDs.splice(pos1,1)
      this.linkDocId.splice(pos2,1)
    } else {
      this.value.push(id);
      this.linkDocId.push(id)
      this.allDocIDs.push(id)
    }
    console.log(this.linkDocId);
  //  this.getAllAsha();
  }
getSelectedArogyamitraSupervisor(){
  let postData={
    doctorId:this.doctorId
  }
  this.adminService.getSelectedArogyamitraSupervisor(postData).subscribe((response:any) => {

    if (response) {
      console.log("superVis",response)
      var supervisorName=response.supervisor
      console.log(supervisorName)
      supervisorName.map((ins:any)=>{
        if(ins.secondparty){
          this.supervisorName=ins.secondparty

        }
      })

    }
  })
}


  onSearchFilter = (event: Event, type: string) => {
    const filterValue = (event.target as HTMLInputElement).value;

    switch (type) {
      case 'searchTotalPatients':
        this.totalPatients.filter = filterValue.trim().toLowerCase();
        break;

      case 'searchMedicalConditions':
        this.medicalConditions.filter = filterValue.trim().toLowerCase();
        break;

      case 'searchHealthInstalled':
        this.healthInstalled.filter = filterValue.trim().toLowerCase();
        break;

        case 'searchHealthNotInstalled':
          this.healthNotInstalled.filter = filterValue.trim().toLowerCase();
        break;
        case 'searchTakenVaccine':
          this.totalTakenVaccine.filter = filterValue.trim().toLowerCase();
        break;
        case 'searchPendingVaccine':
          this.totalPendingVaccine.filter = filterValue.trim().toLowerCase();
        break;
        case 'searchLabRequisitions':
          this.totalLabRequisitions.filter = filterValue.trim().toLowerCase();
        break;
        case 'searchAbhaCreated':
          this.totalAbhaCreated.filter = filterValue.trim().toLowerCase();
        break;
        case 'searchPendingVaccineForSevenDays':
          this.totalPendingVaccineForSevenDays.filter = filterValue.trim().toLowerCase();
        break;
        case 'searchForthcomingVaccines':
          this.totalForthcomingVaccines.filter = filterValue.trim().toLowerCase();
        break;
        case 'searchPendingVaccines':
          this.totalPendingVaccines.filter = filterValue.trim().toLowerCase();
        break;
        case 'searchOverDueVaccines':
          this.totalOverDueVaccines.filter = filterValue.trim().toLowerCase();
        break;

        case 'searchCustomers':
          this.totalCustomers.filter = filterValue.trim().toLowerCase();
        break;


        case 'searchLabReports':
          this.totalLabReports.filter = filterValue.trim().toLowerCase();
        break;
      default:
        break;
    }

  }

  removeDuplicates(arr:any) {
    return arr.filter((item:any,
      index:any) => arr.indexOf(item) === index);
  }

  openDialog(item:any) {

    let content:any = null;

    switch (item.name) {
      case "Medical Conditions":
        content = this.medicalConditionsView;
        break;

      case "Total Patients":
        content = this.totalPatientsView;
        break;

      case "Health App Installed":
        content = this.healthInstalledView;
        break;

      case "Health Not Installed":
        content = this.healthNotInstalledView;
        break;
        case "Vaccination Given":
          content = this.totalTakenVaccineView;
          break;
          // case "Pending Vaccines":
          //   content = this.totalPendingVaccineView;
          //   break;

            case "Lab Requisitions":
              content = this.totalLabRequisitionsView;
              break;

              case "Abha Created":
                content = this.totalAbhaCreatedView;
                break;
                case "Due Vaccine":
                  content = this.totalPendingVaccineForSevenDaysView;
                  break;
                  case "Forthcoming Vaccines":
                    content = this.totalForthcomingVaccinesView;
                    break;
                    case "Pending Vaccines":
                      content = this.totalPendingVaccinesView;
                      break;

                      case "OverDue Vaccines":
                        content = this.totalOverDueVaccinesView;
                        break;

                        case "Unique Family":
                          content = this.totalCustomersView;
                          break;
                        case "Lab Reports":
                          content = this.totalLabReportsView;
                          break;
                          case "Total Prescription":
                          content = this.totalPrescriptionView;
                          break;


      default:
        break;
    }

    if (content) {
      this.dialog.open(content, {

        height: 'fit-content',
        width: '100%',

      });
    }
  }
  closeDialog() {
    this.dialog.closeAll()
  }



  getAllLinkedRecords() {
    let postData = {
      user_type: "s"
    }

    this.adminService.getAllLinkedRecords(postData).subscribe((response:any) => {

      if (response) {
        console.log(response)
        this.supervisorList = response.allLinkRecord

      }
    })

  }

  parseDate(dateObject:any) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  getArogyamitraArray(){
    this.microclusterArray=[]
    let postData = {
      linkage_type: this.linkTypeDoc,
      doctorId: this.doctorId
    }
    this.adminService.getSelectedLinkedRecords(postData).subscribe((response:any) => {

      if (response) {
        console.log("ddd",response)

       if(this.linkTypeDoc=="AM-AS"){
        this.ashaList = response.getAllRecords
        this.ashaList.map((ins:any)=>{
          if(ins.microcluster!=null){
            this.microclusterArray.push(ins)
            this.microclusterArray = this.microclusterArray.reduce((unique:any, o:any) => {
              if (!unique.some((obj:any) => obj.microcluster == o.microcluster)) {
                unique.push(o);
              }
              return unique;
            }, []);
          }
        })
       }
       else {
        this.arogyamitraList = response.getAllRecords
       }
       
        console.log("arog",this.arogyamitraList)
      }
    })
   
  }

  // getAllAshaId() {
  //   this.asha_dropdown.close();
  //   this.disableSearchbutton=true
  // }
  getAllAsha() {
    this.am_dropdown.close();
    this.microclusterArray=[]
    var linkDocId:any = []
    this.selectedArogyamitraArray.map((itr:any) => {
      if (itr.linked_doc_id) {
        linkDocId.push(itr.linked_doc_id)
      }
    })
    console.log(linkDocId)
    let postData = {
      linkage_type: "AM-AS",
      doctorId: this.linkDocId.join(",")
    }
    this.adminService.getSelectedLinkedRecords(postData).subscribe((response:any) => {

      if (response) {
        this.ashaList = response.getAllRecords
        this.ashaListOrg=response.getAllRecords
        console.log('AshaList', this.ashaList)
        this.ashaList.map((ins:any)=>{
          if(ins.microcluster!=null){
            this.microclusterArray.push(ins)
            this.microclusterArray = this.microclusterArray.reduce((unique:any, o:any) => {
              if (!unique.some((obj:any) => obj.microcluster == o.microcluster)) {
                unique.push(o);
              }
              return unique;
            }, []);
          }
        })
      }
    })

  
    console.log("???????",this.microclusterArray)
  }

  onClickViewDocuments(document:any) {
    window.open(document.url)
    }

  onDropdownSelectashaName(item:any) {
    let selectedFieldsArray = this.ashaList
    let selectedVitals: any = []
    selectedFieldsArray.map((ins:any) => {
      item.map((itr:any) => {
        if (ins.secondparty == itr) {
          // ins.selected = true
          this.allDocIDs.push(ins.linked_doc_id)
        }
      })

    })
    //this.allDocIDs=selectedVitals
    console.log('selecteddocids', this.allDocIDs)

  }

  onDropdownSelectarogyamitra(item:any,e:any) {
    let selectedFieldsArray = this.arogyamitraList
    let selectedVitals: any = []
    let arogyamitraIds: any = []

    selectedFieldsArray.map((ins:any) => {
      item.map((itr:any) => {
        if (ins.secondparty == itr) {
           ins['selected'] = true
          selectedVitals.push(ins)
          this.allDocIDs.push(ins.linked_doc_id)
          this.allDocIDs= this.removeDuplicates(this.allDocIDs);
        }
        else{
          if(itr==0){
            ins['selected'] = true
            this.allDocIDs.push(ins.linked_doc_id)
          }
        }
      
         
      
      })
    })
    var data = selectedVitals;
    this.selectedArogyamitraArray = data
    //  this.allDocIDs=arogyamitraIds

    // console.log(this.selectedArogyamitraArray)
    console.log('selecteddocids', this.allDocIDs)

  }
  selectAsha(value:any){
    console.log("LLLLL",value)
   let  supervisorSelectData:any=[]
    this.supervisorList.map((ins:any) => {
      if (ins.fullName == value) {
        supervisorSelectData.push(ins)
      }
    })
    let postData = {
      linkage_type:"S-AS",
      doctorId:supervisorSelectData[0].doctor_id
    }

    this.adminService.getSelectedLinkedRecords(postData).subscribe((response:any) => {

      if (response) {
        console.log(response)
     
       
          this.ashaList = response.getAllRecords
          this.ashaListOrg= response.getAllRecords
        
        



      }
    })

  }
  getAllsupervisor(){
    this.sm_dropdown.close()
    let doctorId = ""

    this.supervisorSelectData = []
    this.arogyamitraListSelectData = []


    let postData = {
      linkage_type: "S-AM",
      doctorId: this.linkDocId.join(",")
    }

    this.adminService.getSelectedLinkedRecords(postData).subscribe((response:any) => {

      if (response) {
        console.log(response)
        
          this.arogyamitraList = response.getAllRecords
        
        //  if (linkage_type == "S-AS") {
        //   this.ashaList = response.getAllRecords
        
        // }



      }
    })
  }
  onDropdownSelectSupervisor(key:any, value:any) {
    let doctorId = ""

    this.supervisorSelectData = []
    this.arogyamitraListSelectData = []
    console.log(value)
    let linkage_type = ""
    if (key == 'supervisor') {


      this.supervisorList.map((ins:any) => {
        if (ins.fullName == value) {
          this.supervisorSelectData.push(ins)
        }
      })
      if (this.supervisorSelectData[0].isAsha == "s") {
        linkage_type = "S-AM"
        doctorId = this.supervisorSelectData[0].doctor_id
        this.allDocIDs.push(doctorId)

      }
      // if (this.supervisorSelectData[0].isAsha == "s") {
      //   linkage_type = "S-AS"
      //   doctorId = this.supervisorSelectData[0].doctor_id

      // }
    }

    let postData = {
      linkage_type: linkage_type,
      doctorId: doctorId
    }
    this.adminService.getSelectedLinkedRecords(postData).subscribe((response:any) => {

      if (response) {
        console.log(response)
        if (linkage_type == "S-AM") {
          this.arogyamitraList = response.getAllRecords
        }
        //  if (linkage_type == "S-AS") {
        //   this.ashaList = response.getAllRecords
        
        // }



      }
    })

    console.log(this.supervisorSelectData)
  }

  getAllMonitoringRecordsCount() {
    this.disableSearchbutton=false
    this.showLoading=true
    if(this.startDate==""|| this.endDate==""){
      this.messageService.add({ severity: 'custom', detail: 'Please choose Date proceeding' });
      this.disableSearchbutton=true
    }
    else{

   
    
    this.allMonitoringCount = []
    this.allReportandRequsitionCount=[]
    this.allVaccinesCount=[]
    this.totalPatientCount = 0
    console.log("sele", this.selectedArogyamitraArray)
    let docIds: any = []
    this.selectedArogyamitraArray.map((itr:any) => {
      if (itr.doctor_id) {
        docIds.push(itr.doctor_id)
      }
      if (itr.linked_doc_id) {
        docIds.push(itr.linked_doc_id)
      }
    });

    this.allDocIDs = this.removeDuplicates(this.allDocIDs);
    console.log('ALLD', this.allDocIDs)

    let startDate = this.reverseDate(this.startDate)
    let endDate = this.reverseDate(this.endDate)
    let postData = {
      startDate: moment(new Date(this.parseDate(startDate))).format("YYYY-MM-DD"),
      endDate: moment(new Date(this.parseDate(endDate))).format("YYYY-MM-DD"),
      doctorId: this.allDocIDs.join(",")
    }
    console.log(postData);

    // temporary post data
    // postData = {
    //   "startDate": "2023-03-01",
    //   "endDate": "2023-03-30",
    //   "doctorId": "11350,11452,11367"
    // }
  

    this.adminService.monitoringRecordsCount(postData).subscribe((response:any) => {
      if (response) {
        console.log("Apr3",response)
        var totalPre:any=[]
        var totalLab:any=[]
        this.showLoading=false
        this.totalPatients = response.totalPatientsCount
        this.healthInstalled = response.queryTotalHealth
        this.healthNotInstalled = response.totalHealthNotInstalledCustomers
        this.medicalConditions = response.medicalConditions
        this.totalTakenVaccine = response.totalTakenVaccine
        this.totalPendingVaccine = response.totalPendingVaccine
        this.totalLabRequisitions=response.totalLabRequisitions
        this.totalAbhaCreated=response.totalAbhaCreated
        this.totalPendingVaccineForSevenDays=response.totalPendingVaccineForSevenDays
        this.totalForthcomingVaccines=response.totalForthcomingVaccines
       this.totalPendingVaccines=response.totalPendingVaccines
       this.totalOverDueVaccines=response.totalOverDueVaccines
       this.totalCustomers=response.totalCustomers
       this.totalLabReports=response.totalLabReports
       this.totalLabReports.map((ins:any)=>{
        if(ins.documentType=="Prescription"){
         totalPre.push(ins)
        }
        if(ins.documentType=="Lab Report"){
         totalLab.push(ins)
        }

       })
       var data:any={}
     this.totalPrescriptionObj["totalPrescription"]=totalPre 
     
       this.totalPrescription = new MatTableDataSource(totalPre)
       this.totalLabReports = new MatTableDataSource(this.totalLabReports)
       this.totalCustomers = new MatTableDataSource(this.totalCustomers)
        this.totalPatients = new MatTableDataSource(this.totalPatients)
        this.medicalConditions = new MatTableDataSource(this.medicalConditions);
        this.healthInstalled = new MatTableDataSource(this.healthInstalled)
        this.healthNotInstalled = new MatTableDataSource(this.healthNotInstalled)
        this.totalTakenVaccine = new MatTableDataSource(this.totalTakenVaccine)
        this.totalPendingVaccine = new MatTableDataSource(this.totalPendingVaccine)
        this.totalLabRequisitions = new MatTableDataSource(this.totalLabRequisitions)
        this.totalAbhaCreated = new MatTableDataSource(this.totalAbhaCreated)
        this.totalForthcomingVaccines = new MatTableDataSource(this.totalForthcomingVaccines)
        this.totalPendingVaccines = new MatTableDataSource(this.totalPendingVaccines)
        this.totalOverDueVaccines = new MatTableDataSource(this.totalOverDueVaccines)
        this.totalPendingVaccineForSevenDays = new MatTableDataSource(this.totalPendingVaccineForSevenDays)
      
        if (response.totalPatientsCount) {
          this.allMonitoringCount.push({ "name": "Total Patients", value: response.totalPatientsCount.length })
        }
        if (response.totalCustomers) {
          this.allMonitoringCount.push({ "name": "Unique Family", value: response.totalCustomers.length })
        }
        if (response.queryTotalHealth) {
          this.allMonitoringCount.push({ "name": "Health App Installed", value: response.queryTotalHealth.length })
        }
        if (response.totalHealthNotInstalledCustomers) {
          this.allMonitoringCount.push({ "name": "Health Not Installed", value: response.totalHealthNotInstalledCustomers.length })
        }
        if (response.totalAbhaCreated) {
          this.allMonitoringCount.push({ "name": "Abha Created", value: response.totalAbhaCreated.length })
        }
        if (response.medicalConditions) {
          this.allMonitoringCount.push({ "name": "Medical Conditions", value: response.medicalConditions.length })
        }
        if (response.totalTakenVaccine) {
          this.allVaccinesCount.push({ "name": "Vaccination Given", value:response.totalTakenVaccine.length })

        }
      
        if (response.totalPendingVaccineForSevenDays) {
          this.allVaccinesCount.push({ "name": "Due Vaccine", value: response.totalPendingVaccineForSevenDays.length })
        }
        if (response.totalForthcomingVaccines) {
          this.allVaccinesCount.push({ "name": "Forthcoming Vaccines", value: response.totalForthcomingVaccines.length })
        }
         if (response.totalPendingVaccines) {
          this.allVaccinesCount.push({ "name": "Pending Vaccines", value: response.totalPendingVaccines.length })
        }
        if (response.totalOverDueVaccines) {
          this.allVaccinesCount.push({ "name": "OverDue Vaccines", value: response.totalOverDueVaccines.length })
        }
        if (response.totalLabRequisitions) {
          this.allReportandRequsitionCount.push({ "name": "Lab Requisitions", value: response.totalLabRequisitions.length })
        } 
        if (response.totalLabReports) {
          this.allReportandRequsitionCount.push({ "name": "Lab Reports", value: response.totalLabReports.length })
        }
        if (this.totalPrescriptionObj.totalPrescription) {
          this.allReportandRequsitionCount.push({ "name": "Total Prescription", value: this.totalPrescriptionObj.totalPrescription.length })
        }
       
      }

    })
  }
  }



  DownloadExcel(filename: any, elementId: any) {
    this.fileName = filename;
    this.exportexcel(elementId);
  }

  exportexcel(elementId: any): void {
    const element = document.getElementById(elementId);
    if (!element) {
      console.error(`Element with ID ${elementId} not found.`);
      return;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }


  onDropdownSelectMicrocluster(item:any){
    this.selectedmicrocluster=[]
    let selectedFieldsArray = this.ashaList
    let microcluster: any = []
    selectedFieldsArray.map((ins:any) => {
      item.map((itr:any) => {
        if (ins.microcluster == itr) {
          // ins.selected = true
          this.selectedmicrocluster.push(ins)
        }
      })

    })
   // this.ashaList=microcluster
  }

  getAllmicrocluster(){
    this.ashaList=this.selectedmicrocluster
    this.microcluster_dropdown.close();
    this.disableSearchbutton=true
  }


  logoutMonitoringRecord() {
    localStorage.removeItem("lock");
    this.authService.deleteToken(0);
  }
  hideShowTextBox(){
    if(this.hideTextBox==false){
      this.hideTextBox=true
    }
    else{
      this.hideTextBox=false
    }

  }

  openDialogModal(content:any) {
   
    this.dialog.open(content);
  }
  onEndDateChange(event:any){
    this.disableSearchbutton=true
  }
  onDateChange(event:any) {
    this.disableSearchbutton=true
    var followupDate: any = this.reverseDate(event.value);
    followupDate = moment(new Date(this.parseDate(followupDate))).format("MM/DD/YYYY");
   // this.endDate = new Date(followupDate)

  }

 
 

  search(value: string) {
    let filter = value.toLowerCase();
    if(value==""){
      this.ashaList=this.ashaListOrg
    }
    else{

   
   this.ashaList= this.ashaListOrg.filter((option:any) =>
      option.secondparty.toLowerCase().startsWith(filter)
    );
   console.log(this.ashaList)
  }
}

onCloseMethod(select:MatSelect){
  console.log('closed')

  this.am_dropdown.close();
  this.microclusterArray=[]
  this.ashaList=[]
  let postData = {
    linkage_type: "AM-AS",
    doctorId: this.linkDocId.join(",")
  }
  this.adminService.getSelectedLinkedRecords(postData).subscribe((response:any) => {

    if (response) {
      this.ashaList = response.getAllRecords
      this.ashaListOrg=response.getAllRecords
      console.log('AshaList', this.ashaList)
      this.ashaList.map((ins:any)=>{
        if(ins.microcluster!=null){
          this.microclusterArray.push(ins)
          this.microclusterArray = this.microclusterArray.reduce((unique:any, o:any) => {
            if (!unique.some((obj:any) => obj.microcluster == o.microcluster)) {
              unique.push(o);
            }
            return unique;
          }, []);
        }
      })
    }
  })


  console.log("???????",this.microclusterArray)

}

onCloseSupervisorMethod(select:MatSelect){
  this.sm_dropdown.close()
  this.supervisorSelectData = []
  this.arogyamitraListSelectData = []


  let postData = {
    linkage_type: "S-AM",
    doctorId: this.arogyamitraId.length? this.arogyamitraId.join(","):0
  }

  this.adminService.getSelectedLinkedRecords(postData).subscribe((response:any) => {

    if (response) {
      console.log(response)
      
        this.arogyamitraList = response.getAllRecords

    }
  })

}

onCloseMicroclusterMethod(select:MatSelect){
  this.ashaList=this.selectedmicrocluster
  this.microcluster_dropdown.close();
  this.disableSearchbutton=true
}
onCloseAshaMethod(select:MatSelect){
  this.asha_dropdown.close();
  this.disableSearchbutton=true
}
 
}
